import { VistaprintAuth, useIdentity } from '@vp/auth-react';
import { FlexBox, PipeSeparator, Typography } from '@vp/swan';
import React from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { SwanButton } from '../Button/SwanButton';
import { DebugItem } from './DebugItem';

export const DebugBanner: React.FC = () => {
  const auth = new VistaprintAuth.WebAuth();
  const identity = useIdentity();
  const [isDebugMode] = useQueryParam('debug', BooleanParam);

  if (isDebugMode === undefined) return null;

  return (
    <FlexBox flexDirection="row" p={4} backgroundColor="light-grey" justifyContent="space-around">
      <Typography fontWeight="bold" component="span">
        Debug Toolbar
      </Typography>
      <PipeSeparator />
      <DebugItem label="User">
        Not available yet. It should load the current user&apos;s data if present in the db.
      </DebugItem>
      <PipeSeparator />
      <DebugItem label="Auth">
        <SwanButton
          widthVariant="standard"
          sizeVariant="mini"
          onClick={() => {
            !identity.isSignedIn ? auth.signIn() : auth.signOut();
          }}
          mb={4}
        >
          {!identity.isSignedIn ? 'Sign in' : 'Sign out'}
        </SwanButton>
      </DebugItem>
    </FlexBox>
  );
};
