export type StyleModel = {
  foregroundColor: string;
  backgroundColor: string;
  logo: string;
  logoUrl: string;
  dotPattern: string;
  markerTemplate: MarkerTemplate;
  isInverted?: boolean;
};

export enum MarkerTemplate {
  square = 'version1',
  rounded = 'version11',
  points1 = 'version3',
  points2 = 'version4',
}

export enum DotPatterns {
  default = '',
  rounded1 = 'rounded-1',
  rounded2 = 'rounded-2',
  dots = 'dots',
  squareDot = 'square',
  connectHorizontal = 'connect-horizontal',
}
