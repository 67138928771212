exports.components = {
  "component---node-modules-pnpm-vp-digital-gatsby-plugin-index-0-16-1-vp-digital-auth-lib-0-18-0-vp-digital-environment-li-kdwxtymhe-5-dac-4-rdkc-6-vqelozu-node-modules-vp-digital-gatsby-plugin-index-src-index-index-tsx": () => import("./../../../node_modules/.pnpm/@vp+digital-gatsby-plugin-index@0.16.1_@vp+digital-auth-lib@0.18.0_@vp+digital-environment-li_kdwxtymhe5dac4rdkc6vqelozu/node_modules/@vp/digital-gatsby-plugin-index/src/index/Index.tsx" /* webpackChunkName: "component---node-modules-pnpm-vp-digital-gatsby-plugin-index-0-16-1-vp-digital-auth-lib-0-18-0-vp-digital-environment-li-kdwxtymhe-5-dac-4-rdkc-6-vqelozu-node-modules-vp-digital-gatsby-plugin-index-src-index-index-tsx" */),
  "component---node-modules-pnpm-vp-digital-gatsby-plugin-index-0-16-1-vp-digital-auth-lib-0-18-0-vp-digital-environment-li-kdwxtymhe-5-dac-4-rdkc-6-vqelozu-node-modules-vp-digital-gatsby-plugin-index-src-page-index-page-index-tsx": () => import("./../../../node_modules/.pnpm/@vp+digital-gatsby-plugin-index@0.16.1_@vp+digital-auth-lib@0.18.0_@vp+digital-environment-li_kdwxtymhe5dac4rdkc6vqelozu/node_modules/@vp/digital-gatsby-plugin-index/src/page-index/PageIndex.tsx" /* webpackChunkName: "component---node-modules-pnpm-vp-digital-gatsby-plugin-index-0-16-1-vp-digital-auth-lib-0-18-0-vp-digital-environment-li-kdwxtymhe-5-dac-4-rdkc-6-vqelozu-node-modules-vp-digital-gatsby-plugin-index-src-page-index-page-index-tsx" */),
  "component---src-components-pages-builder-index-tsx": () => import("./../../../src/components/pages/builder/index.tsx" /* webpackChunkName: "component---src-components-pages-builder-index-tsx" */),
  "component---src-components-pages-product-page-index-tsx": () => import("./../../../src/components/pages/productPage/index.tsx" /* webpackChunkName: "component---src-components-pages-product-page-index-tsx" */),
  "component---src-components-pages-save-index-tsx": () => import("./../../../src/components/pages/save/index.tsx" /* webpackChunkName: "component---src-components-pages-save-index-tsx" */),
  "component---src-components-pages-wrap-up-index-tsx": () => import("./../../../src/components/pages/wrapUp/index.tsx" /* webpackChunkName: "component---src-components-pages-wrap-up-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

