export default {
  service: {
    merch: {
      url: process.env.MERCH_URL,
    },
    qrCodeGenerator: {
      baseUrl: process.env.GATSBY_QR_CODE_BASE_URL,
      assetsPath: process.env.GATSBY_ASSET_PREFIX_URL,
      welcomePage: process.env.GATSBY_QR_WELCOME,
      builderPage: process.env.GATSBY_QR_BUILDER,
      savePage: process.env.GATSBY_QR_SAVE,
      wrapUpPage: process.env.GATSBY_QR_WRAP_UP,
    },
    care: {
      url:
        process.env.GATSBY_CARE_CHANNEL_PROVIDER_SERVICE_API ||
        'https://channel-provider-service.care.vpsvc.com/v2/phone',
    },
    survey: {
      // originally https://psn.vc/qp5sbll8a7, which redirects to the url below
      url: 'https://web.pisano.co/web_feedback?node_id=5554e376-6470-4e8f-baf0-432214893d23',
    },
  },
  env: process.env.GATSBY_ACTIVE_ENV,
  tenant: process.env.GATSBY_TENANT,
  requestor: process.env.GATSBY_REQUESTOR,
  abExperimentKey: process.env.GATSBY_AB_EXPERIMENT_KEY,
};
