export enum Locales {
  daDK = `da-DK`,
  deAT = `de-AT`,
  deCH = `de-CH`,
  deDE = `de-DE`,
  enAU = `en-AU`,
  enCA = `en-CA`,
  enGB = `en-GB`,
  enIE = `en-IE`,
  enIN = `en-IN`,
  enNZ = `en-NZ`,
  enSG = `en-SG`,
  enUS = `en-US`,
  esES = `es-ES`,
  esUS = `es-US`,
  fiFI = `fi-FI`,
  frBE = `fr-BE`,
  frCA = `fr-CA`,
  frCH = `fr-CH`,
  frFR = `fr-FR`,
  itCH = `it-CH`,
  itIT = `it-IT`,
  nbNO = `nb-NO`,
  nlBE = `nl-BE`,
  nlNL = `nl-NL`,
  ptPT = `pt-PT`,
  svSE = `sv-SE`,
}

export const defaultLocale = Locales.enUS;
