import {
  ModalDialog,
  ModalDialogBody,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogHeader,
  ModalDialogTitle,
} from '@vp/swan';
import React, { type ReactElement, type ReactNode } from 'react';
import { SwanButton } from '../Button/SwanButton';

export const DebugItem = ({ label, children }: { label: string; children: ReactNode }): ReactElement => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      <SwanButton skin="unstyled" onClick={() => setIsOpen(true)}>
        {label}
      </SwanButton>
      <ModalDialog bodyWidth="capped" isOpen={isOpen} onRequestDismiss={() => setIsOpen(false)}>
        <ModalDialogContent aria-labelledby={`${label} dialog`} fullBleed p="2">
          <ModalDialogCloseButton visuallyHiddenLabel="Close" />
          <ModalDialogHeader p="4">
            <ModalDialogTitle id={`${label} dialog`}>{label}</ModalDialogTitle>
          </ModalDialogHeader>
          <ModalDialogBody>{children}</ModalDialogBody>
        </ModalDialogContent>
      </ModalDialog>
    </>
  );
};
