import type { ShapesModel } from 'models/ShapesModel';
import type { SelectOptionsModel } from 'models/selectOptionsModel';
import React from 'react';

const BuildTimeData = React.createContext<BuildTimeDataModel>({
  qrCodeUrls: undefined,
  qrSelectOptions: undefined,
  segmentKey: undefined,
  locale: 'en_us',
  shapesData: undefined,
  workspaceUrl: '/workspace',
});

interface BuildTimeDataModel {
  qrCodeUrls: Array<any> | undefined;
  qrSelectOptions: Array<SelectOptionsModel> | undefined;
  segmentKey: string | undefined;
  locale: string;
  shapesData: ShapesModel | undefined;
  workspaceUrl: string;
}
export default BuildTimeData;
