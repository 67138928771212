import { isEmpty } from 'lodash';

export const isValidUrl = (url: string): boolean => {
  if (url) {
    try {
      const urlWithProtocol = url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
      new URL(urlWithProtocol);
    } catch (e) {
      return false;
    }

    return /^\S+$/.test(url);
  }

  return true;
};

export const validateEmail = (email: string): boolean => {
  const expression =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const regex = new RegExp(expression);
  return !!email.match(regex);
};

export function areRequiredFieldsFilled<T extends Record<string, unknown>>(data: T): boolean {
  //TODO refactor validation logic after mvp in RAP-137
  return Object.keys(data).every(field => {
    const value = data[field as keyof T];
    return !isEmpty(value);
  });
}

//Image upload validation
const imageMaxSize = 50000000;
export const acceptedImageTypes = ['image/png', 'image/jpeg', 'image/svg'];
export const isImageValid = (file: File): boolean => {
  if (file.size > imageMaxSize) {
    console.error(`Image size exceeded maximum size of ${imageMaxSize / 10000000}`);
    return false;
  }
  if (!acceptedImageTypes.includes(file.type)) {
    console.error('Image type is not supported: ', file.type);
    return false;
  }
  return true;
};

//File upload validation
export const applicationPdf = 'application/pdf';
export const imageFilesTypes = ['image/png', 'image/jpeg', 'image/svg+xml'];
export const mediaFilesTypes = [
  'audio/x-mpeg-3',
  'audio/wav',
  'audio/x-wav',
  'audio/aiff',
  'video/mpeg',
  'video/mp4',
  'application/mp4',
];
export const acceptedFileTypes = [applicationPdf, ...imageFilesTypes, ...mediaFilesTypes];
export type UploadFileResultType = 'succeeded' | 'failed' | 'invalidSize' | 'invalidType' | 'invalidNumber';
