import { useSignedInAction } from '@vp/digital-auth-lib';
import { fadeOutAndGoTo } from 'components/common/LinkToPage';
import { defaultQRCodeModel } from 'components/data/QRCodeData';
import { parseState, updatQrCodeModel } from 'components/utils/QrCodeUtils';
import { fetchQrCodeData } from 'features/fetchQrCodeData';
import { createFreeAccount } from 'features/freeAccount';
import get from 'lodash/get';
import type { QRCodeModel, QrState } from 'models/QRCodeModel';
import { QRType } from 'models/QRCodeModel';
import { UrlId } from 'models/gatsby/ExternalUrlId';
import React, { useContext, useEffect, useState, type ReactElement, type ReactNode } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import BuildTimeData from './BuildTimeData';
import { IdentityContext } from './IdentityContext';

interface QrCodeContextModel {
  qrCodeModel: QRCodeModel;
  loading: boolean | null;
  setLoading: (loadingState: boolean | null) => void;
  setQRCodeModel: (data: QRCodeModel) => void;
  updateState: (newSate: QrState) => void;
  qrType: string;
  setQRType: (newInput: QRType) => void;
  saveAndRedirect: () => void;
  errorStatusCode: number | undefined;
  isPreviewUpdating: boolean;
  setIsPreviewUpdating: (newSate: boolean) => void;
  moveToSavePage: () => void;
  altId: string | null | undefined;
}
const QRDataContext = React.createContext<QrCodeContextModel>({} as QrCodeContextModel);

const QRDataProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [altId] = useQueryParam('altId', StringParam);
  const [stateParam] = useQueryParam('state', StringParam);
  const [qrType, setQRType] = useState<QRType>(QRType.URL);
  const [errorStatusCode, setErrorStatusCode] = useState<number | undefined>(undefined);
  const [qrCodeModel, setQRCodeModel] = useState<QRCodeModel>(defaultQRCodeModel(qrType, 'initial'));
  const { canonicalUser, redirected, setRedirected } = useContext(IdentityContext);
  const { workspaceUrl, locale, qrCodeUrls } = useContext(BuildTimeData);
  const [isPreviewUpdating, setIsPreviewUpdating] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean | null>(null);

  const doFreeProvisioning = async () => {
    if (canonicalUser?.canonicalId) {
      try {
        await createFreeAccount({
          qrAltId: altId as string,
          shopperId: canonicalUser.canonicalId,
        });
      } catch (e) {
        console.error('error while provisioning ', e);
      }
    }
  };

  useEffect(() => {
    if (qrCodeModel.responseData && redirected && canonicalUser?.isSignedIn) {
      if (!qrCodeModel.responseData.benefitAltId) {
        doFreeProvisioning().then(() => {
          setRedirected(false);
          window.location.href = workspaceUrl;
        });
      } else {
        setRedirected(false);
        window.location.href = workspaceUrl;
      }
    }
  }, [canonicalUser?.isSignedIn, redirected, qrCodeModel.responseData]);

  function redirect(redirected: boolean): void {
    setRedirected(redirected);
  }

  const [_redirectToWorkspace, error, redirectFunction] = useSignedInAction<boolean, void>(
    redirect,
    'redirect-after-sign-in',
  );
  const saveAndRedirect = async () => {
    try {
      redirectFunction(true);
    } catch (e) {
      console.log(error);
    }
  };

  const moveToSavePage = async () => {
    const qrAltId = qrCodeModel?.responseData?.altId;
    fadeOutAndGoTo(locale, UrlId.QRCODE_SAVE, qrCodeUrls, '?altId=' + qrAltId);
  };

  //if the user navigates to builderPage with an altId => set QRCodeModel values with responseData values
  useEffect(() => {
    // Make sure we already loaded bearer token
    if (!canonicalUser) {
      return;
    }
    const fetchResponseData = async () => {
      setLoading(true);
      try {
        const responseData = await fetchQrCodeData(altId as string);
        setQRCodeModel(
          updatQrCodeModel(responseData, parseState(stateParam), get(responseData, 'qrType.name') as QRType),
        );
      } catch (e) {
        if (e instanceof Error) {
          //get status code from error message
          const errorStatusCode = parseInt(e.message.replace(/^\D+/g, ''));
          setErrorStatusCode(errorStatusCode);
        }
      }
      setLoading(false);
    };
    if (altId) {
      fetchResponseData();
    } else {
      updateState('new');
    }
  }, [altId, canonicalUser]);

  const updateState = (newSate: QrState) => {
    const newQrCodeModel = {
      ...qrCodeModel,
      state: newSate,
    };
    setQRCodeModel(newQrCodeModel);
  };

  return (
    <QRDataContext.Provider
      value={{
        qrCodeModel,
        loading,
        setLoading,
        setQRCodeModel,
        updateState,
        qrType,
        setQRType,
        saveAndRedirect,
        errorStatusCode,
        isPreviewUpdating,
        setIsPreviewUpdating,
        moveToSavePage,
        altId,
      }}
    >
      {children}
    </QRDataContext.Provider>
  );
};

export { QRDataContext, QRDataProvider };
