import type { QRCodeResponseModel } from './QRCodeResponseModel';
import type { StyleModel } from './StyleModel';
import type { UploadFileModel } from './UploadFileModel';
import type { VcardModel } from './VcardModel';

export interface QRCodeModel {
  qrCodeData: QrTypeStructure | undefined;
  style: StyleModel | undefined;
  responseData: QRCodeResponseModel | undefined;
  state: QrState;
}

export interface QrTypeStructure {
  key: QRType;
  value: QRModels;
  userInput: string;
}

export enum QRType {
  WIFI = 'WIFI',
  URL = 'URL',
  VCARD = 'VCARD',
  TEXT = 'TEXT',
  FACEBOOK = 'FACEBOOK',
  TWITTER = 'TWITTER',
  INSTAGRAM = 'INSTAGRAM',
  LOCATION = 'LOCATION',
  REVIEW = 'REVIEW',
  FILE = 'FILE',
}

export type QRModels =
  | LinkModel
  | ShareContactModel
  | SendEmailModel
  | SmsModel
  | SearchWebModel
  | WifiModel
  | UploadFileModel
  | VcardModel;

export const qrStatesList = ['initial', 'new', 'loading', 'ready', 'done', 'editing', 'failed'] as const;
export type QrState = (typeof qrStatesList)[number];
