import { SubBrands, Tenants, VistaAssetStore } from '@design-stack-vista/vista-assets-sdk';
import type { Asset } from 'models/Asset';
import React, { createContext, useContext, useEffect, useState, type ReactElement, type ReactNode } from 'react';
import { IdentityContext } from './IdentityContext';

interface AssetStoreContextModel {
  assetStore?: VistaAssetStore;
  uploadImage: (event: React.ChangeEvent<HTMLInputElement>) => any;
  getAssetsArray: () => Asset[];
  deleteAsset: (index: number) => void;
  uploadFile: (file: File) => Promise<string>;
}

const AssetStoreContext = createContext<AssetStoreContextModel>({} as AssetStoreContextModel);

const AssetStoreProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const { canonicalUser } = useContext(IdentityContext);
  const [assetStore, setAssetStore] = useState<VistaAssetStore>();

  useEffect(() => {
    if (canonicalUser) {
      const authProvider = {
        getAuthHeaders: async () => ({
          Authorization: `Bearer ${canonicalUser?.authToken}`,
        }),
      };
      setAssetStore(
        new VistaAssetStore({
          authProvider,
          subBrand: SubBrands.VistaPrint,
          experience: 'qrCodeGenerator',
          writeTenant: Tenants.VistaPrint,
        }),
      );
    }
  }, [canonicalUser]);

  const uploadFile = async (file: File): Promise<string> => {
    const asset = await assetStore!.upload({ file, expires: 'never' });
    await asset.presign();
    return asset.getUrl();
  };

  const uploadImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      const asset = await assetStore!.upload({ file: e.currentTarget.files![0], expires: 'never' });
      await asset.presign();
    }
  };

  const deleteAsset = async (index: number) => {
    const asset = assetStore!.assets[index];
    await asset.delete();
  };

  const getAssetsArray = () => {
    const assetsArray: Array<Asset> = [];
    assetStore!.assets.forEach((asset: any) => {
      asset.presign();
      const url = asset.getUrl({ includeSignature: true }) as string;
      const name = asset.data?.info?.storage?.fileName ?? '';
      if (url.includes('signature=') && name) {
        assetsArray.push({ name, url });
      }
    });
    return assetsArray;
  };

  return (
    <AssetStoreContext.Provider value={{ assetStore, uploadImage, getAssetsArray, deleteAsset, uploadFile }}>
      {children}
    </AssetStoreContext.Provider>
  );
};

export { AssetStoreContext, AssetStoreProvider };
