import { useLocale } from '@vp/digital-locale-lib';
import i18next from 'i18next';
import get from 'lodash/get';
import type { ReactElement } from 'react';
import React, { useMemo } from 'react';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import type { LocalizationProviderProps } from 'typings/localization';

export default function LocalizationProvider(props: LocalizationProviderProps): ReactElement {
  const { translations, defaultLocaleTranslations, children } = props;
  const { identifier: locale } = useLocale();

  const i18n = useMemo(() => {
    const resources = {
      [locale]: {
        translation: translations,
      },
      [get(defaultLocaleTranslations, 'locale', '')]: {
        translation: get(defaultLocaleTranslations, 'translations', ''),
      },
    };
    const i18n = i18next.use(initReactI18next);
    i18n.init({
      lng: locale,
      returnEmptyString: false,
      fallbackLng: {
        default: [get(defaultLocaleTranslations, 'locale', '')],
      },
      interpolation: {
        escapeValue: false,
        prefix: '[[',
        suffix: ']]',
      },
      keySeparator: false,
      resources,
    });
    return i18n;
  }, [locale, translations, defaultLocaleTranslations]);

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
}
