import type { ButtonProps } from '@vp/swan';
import { Button, Icon, Spinner } from '@vp/swan';
import type { ReactElement } from 'react';
import React from 'react';
import { isMobile } from 'react-device-detect';

interface Props {
  iconType?:
    | 'standard'
    | 'error'
    | 'menu'
    | 'filter'
    | 'image'
    | 'text'
    | 'view'
    | 'search'
    | 'list'
    | 'rotate'
    | 'square'
    | 'email'
    | 'download'
    | 'reset'
    | 'close'
    | 'focus'
    | 'pause'
    | 'play'
    | 'vertical'
    | 'help'
    | 'add'
    | 'account'
    | 'alignCenter'
    | 'alignLeft'
    | 'alignRight'
    | 'arrowLeft'
    | 'arrowRight'
    | 'bringToFront'
    | 'browseDesigns'
    | 'calendar'
    | 'caretDown'
    | 'caretUp'
    | 'chatReview'
    | 'check'
    | 'chevronLeft'
    | 'chevronRight'
    | 'clock'
    | 'closeLarge'
    | 'closeSmall'
    | 'deleteStudio'
    | 'delete'
    | 'designEducation'
    | 'designerMarketplace'
    | 'edit'
    | 'fileAi'
    | 'fileEps'
    | 'fileGeneric'
    | 'fileId'
    | 'filePdf'
    | 'filePsd'
    | 'fullCurveDown'
    | 'fullCurveUp'
    | 'fullDesign'
    | 'guaranteedSatisfaction'
    | 'heart'
    | 'helpQuery'
    | 'info'
    | 'liveChat'
    | 'login'
    | 'logo'
    | 'magic'
    | 'minus'
    | 'myDigitalMarketing'
    | 'myProjects_1'
    | 'myProjects'
    | 'numericList'
    | 'orderHistory'
    | 'passwordHide'
    | 'passwordShow'
    | 'phoneCall'
    | 'phone'
    | 'plusRounded'
    | 'plus'
    | 'polish'
    | 'preview'
    | 'priceTag'
    | 'printedProducts'
    | 'qrCode'
    | 'redoLegacy'
    | 'redo'
    | 'reorder'
    | 'roundedCorners'
    | 'satisfaction'
    | 'saveAsCopy'
    | 'save'
    | 'searchLarge'
    | 'sendToBack'
    | 'shapes'
    | 'shoppingBag_0'
    | 'shoppingBag_1'
    | 'shoppingBag_10'
    | 'shoppingBag_11'
    | 'shoppingBag_12'
    | 'shoppingBag_13'
    | 'shoppingBag_14'
    | 'shoppingBag_2'
    | 'shoppingBag_3'
    | 'shoppingBag_4'
    | 'shoppingBag_5'
    | 'shoppingBag_50'
    | 'shoppingBag_6'
    | 'shoppingBag_7'
    | 'shoppingBag_8'
    | 'shoppingBag_9'
    | 'slightCurveDown'
    | 'slightCurveUp'
    | 'sparkle'
    | 'straight'
    | 'tables'
    | 'templates'
    | 'twoSide'
    | 'undoLegacy'
    | 'undo'
    | 'uploadYourArtwork'
    | 'upload'
    | 'wayfinding'
    | 'workWithAPro'
    | 'zoomIn'
    | 'zoomOut';
  minWidth?: number;
  icon?: () => ReactElement;
  loading?: boolean;
  fullWidthOnMobile?: boolean;
}

export const SwanButton: React.FC<Props & ButtonProps> = props => {
  const { children, fullWidthOnMobile, loading, icon, iconPosition, iconType, style, minWidth, ...rest } = props;
  const customStyle: React.CSSProperties = {};
  if (minWidth !== undefined) {
    customStyle.minWidth = minWidth;
  }
  let titleStyle = {};
  if (iconPosition === 'left' || iconPosition === 'right') {
    titleStyle = {
      width: '100%',
    };
  }
  return (
    <Button
      width={isMobile && fullWidthOnMobile ? 'full-width' : 'standard'}
      iconPosition={iconPosition}
      style={{ ...style, ...customStyle }}
      {...rest}
    >
      {iconType && <Icon iconType={iconType}></Icon>}
      {icon && (
        <span style={{ display: 'flex', alignItems: 'center' }} className="swan-icon swan-icon-skin-standard">
          {icon()}
        </span>
      )}
      {children} {loading && <Spinner marginLeft={2} accessibleText="something" size={'tiny'} />}
    </Button>
  );
};
