import { Environment } from '@vp/digital-environment-lib';
import { LogLevel, getMfeLoggingAttributes, initConsoleLogger, initCustomLogger } from '@vp/digital-logging-lib';
import { NewRelicApiLogger, NewRelicApiLoggerAdapter } from '@vp/digital-newrelic-logging-lib';

import packageJson from './package.json';
const GATSBY_ACTIVE_ENV = process.env.GATSBY_ACTIVE_ENV || 'Local';
const GATSBY_NEW_RELIC_LOGGING_LICENSE_KEY = process.env.GATSBY_NEW_RELIC_LOGGING_LICENSE_KEY || '';
const GATSBY_NEW_RELIC_INGESTION_BASE_URL = process.env.GATSBY_NEW_RELIC_INGESTION_BASE_URL || '';
const { name, version } = packageJson;

export const getActiveEnvironment = (): Environment => {
  switch (process.env.GATSBY_ACTIVE_ENV) {
    case 'Staging':
      return Environment.Staging;
    case 'Prod':
      return Environment.Prod;
    default:
      return Environment.Local;
  }
};

export function initNodeLogger() {
  const meta = getMfeLoggingAttributes(name, version, 'gatsby-node', GATSBY_ACTIVE_ENV);
  const loggerOptions = {
    desiredLogLevel: LogLevel.error,
  };

  initConsoleLogger(meta, loggerOptions);
}

export function initSsrLogger() {
  const meta = getMfeLoggingAttributes(name, version, 'gatsby-ssr', GATSBY_ACTIVE_ENV);
  const loggerOptions = {
    desiredLogLevel: LogLevel.error,
  };
  initConsoleLogger(meta, loggerOptions);
}

export function initBrowserLogger() {
  const environment = getActiveEnvironment();
  const meta = getMfeLoggingAttributes(name, version, 'gatsby-browser', environment);
  const loggerOptions = {
    desiredLogLevel: LogLevel.info,
  };
  if (environment != Environment.Prod) {
    initConsoleLogger(meta, loggerOptions);
  } else {
    const nrApiLogger = new NewRelicApiLogger(
      GATSBY_NEW_RELIC_INGESTION_BASE_URL,
      GATSBY_NEW_RELIC_LOGGING_LICENSE_KEY,
      1000,
      meta,
    );
    const nrApiLoggerAdapter = new NewRelicApiLoggerAdapter(nrApiLogger, meta);
    initCustomLogger(nrApiLoggerAdapter, loggerOptions);
  }
}
