module.exports = [{
      plugin: require('../node_modules/.pnpm/@vp+gatsby-plugin-static-site@1.4.37_gatsby@5.13.6/node_modules/@vp/gatsby-plugin-static-site/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/@vp+digital-gatsby-plugin-rollbar-provider@0.18.0_@rollbar+react@0.8.0_@vp+digital-auth-lib@0_3dswovz5gwe2yipl24decvptqe/node_modules/@vp/digital-gatsby-plugin-rollbar-provider/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"43c47eba7c1a491185f8aa37d5153626","captureUncaught":true,"captureUnhandledRejections":true,"payload":{"environment":"Prod","client":{"javascript":{"code_version":"431fe9d","source_map_enabled":true,"guess_uncaught_frames":true}}}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-use-query-params-v2@2.0.0_gatsby@5.13.6_react-dom@18.2.0_react@18.2.0_use-query-params@2.2.1/node_modules/gatsby-plugin-use-query-params-v2/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.6_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@5.3.3/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
