import type { VcardModel } from 'models/VcardModel';

export const defaultVcardData: VcardModel = {
  firstName: '',
  lastName: '',
  role: '',
  company: '',
  website: '',
  email: '',
  address: '',
  phone: [''],
  fax: '',
  note: '',
};

export const vCardInputFields = [
  {
    name: 'firstName',
    type: 'text',
    required: true,
  },
  {
    name: 'lastName',
    type: 'text',
    required: true,
  },
  {
    name: 'role',
    type: 'text',
    required: false,
  },
  {
    name: 'company',
    type: 'text',
    required: false,
  },
  {
    name: 'website',
    type: 'text',
    required: false,
  },
  {
    name: 'email',
    type: 'email',
    required: true,
  },
  {
    name: 'address',
    type: 'text',
    required: false,
  },
  {
    name: 'phone',
    type: 'number',
    required: true,
  },
  {
    name: 'fax',
    type: 'tel',
    required: false,
  },
  {
    name: 'note',
    type: 'text',
    required: false,
  },
];

export enum VCardFields {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  ROLE = 'role',
  COMPANY = 'company',
  WEBSITE = 'website',
  EMAIL = 'email',
  ADDRESS = 'address',
  PHONE = 'phone',
  FAX = 'fax',
  NOTE = 'note',
}

export const simpleInputVCardFields: Array<string> = [
  VCardFields.FIRST_NAME,
  VCardFields.LAST_NAME,
  VCardFields.ROLE,
  VCardFields.COMPANY,
  VCardFields.ADDRESS,
  VCardFields.FAX,
  VCardFields.NOTE,
];
