import VistaprintAuth from '@vp/auth';
import type { AxiosPromise, AxiosRequestConfig } from 'axios';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const accessTokenRequest = async (config: AxiosRequestConfig): Promise<AxiosPromise> => {
  const auth = new (VistaprintAuth.WebAuth as any)();
  const authHeader = auth.getAuthorizationHeader();

  if (!authHeader) {
    console.error('no vp-access-token found');
    throw Error('no vp-access-token found');
  }

  const configWithAuthHeader = {
    ...config,
    headers: {
      ...config.headers,
      'x-request-id': uuidv4(),
      Authorization: authHeader,
    },
  };
  return axios(configWithAuthHeader);
};

export default accessTokenRequest;
