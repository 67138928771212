import { getDefaultQRCodeDataValues } from 'components/utils/QrCodeUtils';
import config from 'config';
import type { QRCodeModel, QrState } from 'models/QRCodeModel';
import { QRType } from 'models/QRCodeModel';
import { DotPatterns, MarkerTemplate } from 'models/StyleModel';

export const defaultQRCodeModel = (key: QRType, state: QrState): QRCodeModel => {
  return {
    qrCodeData: {
      key: key,
      value: { ...getDefaultQRCodeDataValues(key) },
      userInput: '',
    },
    style: {
      foregroundColor: '#000000',
      backgroundColor: '#FFFFFF',
      isInverted: false,
      markerTemplate: MarkerTemplate.square,
      dotPattern: DotPatterns.default,
      logo: '',
      logoUrl: '',
    },
    responseData: undefined,
    state: state,
  };
};
//getting the path from egoditor
export const defaultImageList =
  config.env == 'Prod'
    ? [
        { name: 'ScanMe', id: 'account18587952/logo/67daa3d90bc365001e0f3ebb8617d28e.png' },
        { name: 'Instagram', id: 'account18587952/logo/9da33db7dd5301fc70432c4ebca530f4.png' },
        { name: 'Facebook', id: 'account18587952/logo/bd1aef6b44b753ef18be0b21393c9ca3.png' },
        { name: 'LinkedIn', id: 'account18587952/logo/e968878423e4912c0ba051abe3f513b8.png' },
        { name: 'Twitter', id: 'account18587952/logo/19dbc59a0ad5f0c28398fa68bc11fa2f.png' },
        { name: 'Review', id: 'account18587952/logo/c6fb82f2ece5b8a4841249c256d16253.png' },
        { name: 'Youtube', id: 'account18587952/logo/0cf8657cadb601524785168d04b031de.png' },
        { name: 'Google', id: 'account18587952/logo/3b1ac7c34a027df3532d598d0b47fda8.png' },
        { name: 'Watch', id: 'account18587952/logo/8b2e5e94136f4f121c286a6c5d422dc9.png' },
      ]
    : [
        { name: 'ScanMe', id: 'account18445356/logo/8f499875c51acd10be34b8d5fbdf9de1.png' },
        { name: 'Instagram', id: 'account18445356/logo/8a6aa2c3e3b1a3e5e31c416d0a4a9da6.png' },
        { name: 'Facebook', id: 'account18445356/logo/25c2ae165980e6672e9d70d32e78a77d.png' },
        { name: 'LinkedIn', id: 'account18445356/logo/f21f22a102dfd586cfc27ce86535e3b3.png' },
        { name: 'Twitter', id: 'account18445356/logo/45b193d4ad7324800e15e95f38a514e8.png' },
        { name: 'Review', id: 'account18445356/logo/9a20150d0277b9732bc072853b417945.png' },
        { name: 'Youtube', id: 'account18445356/logo/53d1e2ee63f6655b63fb1d0c4d17f5b7.png' },
        { name: 'Google', id: 'account18445356/logo/e003de9950ff3f74ad0b4aeb2866da48.png' },
        { name: 'Watch', id: 'account18445356/logo/5082d104a81826de9ceaf13c26761fc4.png' },
      ];

export const textInputFormDestinations: Array<string> = [
  QRType.URL,
  QRType.INSTAGRAM,
  QRType.FACEBOOK,
  QRType.TWITTER,
  QRType.REVIEW,
  QRType.LOCATION,
];
