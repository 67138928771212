import impression from '@vp/ab-impression';
import * as reader from '@vp/ab-reader';
import { Environment } from '@vp/digital-environment-lib';
import React, { useEffect, type ReactElement, type ReactNode } from 'react';
import { getActiveEnvironment } from '../../loggerHelpers';
import config from '../config';
interface ABTestingContextModel {
  variationKey: string | null;
}
const ABTestingContext = React.createContext<ABTestingContextModel>({} as ABTestingContextModel);

const ABTestingProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const experimentKey = `${config.abExperimentKey}`;
  // A/B Testing :export the variationKey then get it from any component to display different UI depend on it
  const variationKey = reader.getVariation(experimentKey);

  useEffect(() => {
    reader.whenAvailable(() => {
      const environment = getActiveEnvironment();
      const StagingSdkKey = '4meAob4GcUmibKEYsMEwwA';
      const ProdSdkKey = 'TL6bJ6aeorhsvy5X8LGMVX';
      const sdkKey = [Environment.Staging, Environment.Local].includes(environment) ? StagingSdkKey : ProdSdkKey;
      const firstVariationKey = '1';
      const SecondVariationKey = '2';
      if (variationKey === firstVariationKey) {
        impression.fireImpression(experimentKey, firstVariationKey, sdkKey);
        console.log('A/B Testing : first variation');
      } else if (variationKey === SecondVariationKey) {
        impression.fireImpression(experimentKey, SecondVariationKey, sdkKey);
        console.log('A/B Testing : second variation');
      } else {
        console.log('A/B Testing : default variation');
      }
    });
  }, []);
  return (
    <ABTestingContext.Provider
      value={{
        variationKey,
      }}
    >
      {children}
    </ABTestingContext.Provider>
  );
};

export { ABTestingContext, ABTestingProvider };
