import { defaultLinkData } from 'components/data/LinkData';
import { defaultQRCodeModel, textInputFormDestinations } from 'components/data/QRCodeData';
import { defaultVcardData, vCardInputFields } from 'components/data/VcardData';
import { defaultWifiData } from 'components/data/WifiData';
import { cloneDeep } from 'lodash';
import get from 'lodash/get';
import type { QRCodeModel, QRModels, QrState } from 'models/QRCodeModel';
import { QRType, qrStatesList } from 'models/QRCodeModel';
import type { QRCodeResponseModel } from 'models/QRCodeResponseModel';
import type { VcardModel } from 'models/VcardModel';
import { defaultUploadFileData } from '../data/UploadFileData';
import { areRequiredFieldsFilled, validateEmail } from './validationUtils';

export const isValidQrData = (qrType: string, isFormValid: boolean, qrCodeModel: QRCodeModel) => {
  switch (qrType) {
    case QRType.VCARD: {
      const vcardRequiredfields: Record<string, any> = {};
      vCardInputFields.map(field => {
        if (field.required) {
          const vcardData = qrCodeModel?.qrCodeData?.value as VcardModel;
          vcardRequiredfields[field.name] = vcardData[field.name as keyof VcardModel][0];
        }
      });
      const vcardData = qrCodeModel?.qrCodeData?.value as VcardModel;

      return areRequiredFieldsFilled(vcardRequiredfields) && validateEmail(vcardData.email);
    }
    case QRType.WIFI:
      if (get(qrCodeModel, 'qrCodeData.value.encryption') == '') {
        return areRequiredFieldsFilled({ ssid: get(qrCodeModel, 'qrCodeData.value.ssid') });
      }
      return areRequiredFieldsFilled({
        ssid: get(qrCodeModel, 'qrCodeData.value.ssid'),
        password: get(qrCodeModel, 'qrCodeData.value.password'),
      });
    case QRType.FILE:
      return areRequiredFieldsFilled({ url: get(qrCodeModel, 'qrCodeData.value.url') });
    case textInputFormDestinations.toString():
    default:
      if (get(qrCodeModel, 'qrCodeData.value.url')) {
        return areRequiredFieldsFilled({ url: get(qrCodeModel, 'qrCodeData.value.url') }) && isFormValid;
      }
  }
};

export const updatQrCodeModelDefault = (selectedQrType: QRType, state = 'new' as QrState): QRCodeModel => {
  switch (selectedQrType) {
    case QRType.WIFI:
      return defaultQRCodeModel(QRType.WIFI, state);
    case textInputFormDestinations.toString():
    default:
      return defaultQRCodeModel(QRType.URL, state);
  }
};

export const updateQrCodeData = (qrCodeModel: QRCodeModel, key: string, value: string | boolean | string[]) => {
  return {
    ...qrCodeModel,
    qrCodeData: {
      ...qrCodeModel.qrCodeData,
      value: {
        ...qrCodeModel?.qrCodeData?.value,
        [key]: value,
      },
    },
  };
};

export const updatQrCodeModel = (responseData: QRCodeResponseModel, state: QrState, selectedQrType: QRType) => {
  switch (selectedQrType) {
    case QRType.WIFI:
      return {
        qrCodeData: {
          key: QRType[responseData.qrType.name as keyof typeof QRType],
          value: {
            ...responseData.qrValue.values,
          } as QRModels,
          userInput: responseData.qrValue?.userInput,
        },
        style: responseData.customAttributes,
        responseData,
        state,
      };
    case textInputFormDestinations.toString():
    default:
      return {
        qrCodeData: {
          key: QRType[responseData.qrType.name as keyof typeof QRType],
          value: {
            ...responseData.qrValue,
          } as QRModels,
          userInput: responseData.qrValue?.userInput,
        },
        style: responseData.customAttributes,
        responseData,
        state,
      };
  }
};

export const parseState = (maybeState: unknown): QrState => {
  if (!maybeState) {
    return 'done' as QrState;
  }
  const qrSate = qrStatesList.find(validState => maybeState === validState);
  if (!qrSate) {
    return 'done' as QrState;
  }
  return qrSate;
};

export const getCurrentCodeModel = (
  newValue: string,
  modelToUpdate: QRCodeModel,
  qrType: string,
  inputValue: string,
): QRCodeModel => {
  modelToUpdate.qrCodeData = {
    key: QRType[qrType as keyof typeof QRType],
    value: { url: newValue } as QRModels,
    userInput: inputValue,
  };
  return cloneDeep(modelToUpdate);
};

export const getDefaultQRCodeDataValues = (selectedQrType: QRType): QRModels => {
  switch (selectedQrType) {
    case QRType.VCARD:
      return { ...defaultVcardData };
    case QRType.WIFI:
      return defaultWifiData;
    case QRType.FILE:
      return defaultUploadFileData;
    case textInputFormDestinations.toString():
    default:
      return defaultLinkData;
  }
};
export const updateQRType = (qrCodeModel: QRCodeModel, qrType: QRType): QRCodeModel => {
  return {
    ...qrCodeModel,
    qrCodeData: {
      ...qrCodeModel.qrCodeData,
      key: QRType[qrType as keyof typeof QRType],
      value: {
        ...getDefaultQRCodeDataValues(qrType),
      },
      userInput: '',
    },
  };
};
