import config from '../config';
import { FREE_ACCOUNT } from './Routes/Routes';
import accessTokenRequest from './accesTokenRequest';

export const createFreeAccount = async (opt: { qrAltId: string; shopperId: string }): Promise<any> => {
  const { qrAltId, shopperId } = opt;
  const response = await accessTokenRequest({
    method: 'post',
    url: `${config.service.qrCodeGenerator.baseUrl}${FREE_ACCOUNT}`,
    data: {
      qrAltId,
      shopperId,
    },
  });
  return response.data;
};
