import { BookendsWrapper } from '@vp/digital-bookends-lib';
import React from 'react';
import Helmet from 'react-helmet';

import { HtmlAttributes, MainLayout } from '@vp/digital-site-layout-lib';
import type { TrackingConfiguration } from '@vp/digital-tracking-lib';
import { ScreenClassProvider } from '@vp/swan';

interface PageWrapperProps {
  trackingConfiguration: TrackingConfiguration;
  locale: string;
  tenant: string;
  useBookends: boolean;
  useSlimBookendsHeader?: boolean;
  children: React.ReactNode;
}

function PageWrapper(props: PageWrapperProps) {
  const { trackingConfiguration, locale, children, tenant, useBookends, useSlimBookendsHeader } = props;
  return (
    <>
      <HtmlAttributes />
      <BookendsWrapper
        trackingConfiguration={trackingConfiguration}
        tenant={tenant}
        locale={locale}
        renderMetaWith={Helmet}
        hideFooter={!useBookends}
        hideHeader={!useBookends}
        isSlimHeader={!!useSlimBookendsHeader}
        displayCartIcon={false}
      >
        <MainLayout>
          <ScreenClassProvider>{children}</ScreenClassProvider>
        </MainLayout>
      </BookendsWrapper>
    </>
  );
}

export default PageWrapper;
