import { createRoot } from 'react-dom/client';
import { initBrowserLogger } from './loggerHelpers';

export const onClientEntry = () => {
  // this is a hack to prevent gatsby from changing the site url to an internal route in prod
  (window as any).pagePath = window.location.pathname;

  initBrowserLogger();
};

export { wrapPageElement } from './gatsby-common';

export const replaceHydrateFunction = () => {
  return (element: any, container: any) => {
    const root = createRoot(container);
    root.render(element);
  };
};
