import { QR } from 'features/Routes/Routes';
import config from '../config';
import type { QRCodeResponseModel } from '../models/QRCodeResponseModel';
import accessTokenRequest from './accesTokenRequest';

export const fetchQrCodeData = async (altId: string): Promise<QRCodeResponseModel> => {
  try {
    const response = await accessTokenRequest({
      method: 'get',
      url: `${config.service.qrCodeGenerator.baseUrl}${QR}/${altId}`,
    });
    return response.data as QRCodeResponseModel;
  } catch (error) {
    throw Error(error as string);
  }
};
