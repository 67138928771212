import { useIdentity, useWebAuth } from '@vp/digital-auth-lib';
import React, { useEffect, useState, type ReactElement, type ReactNode } from 'react';

interface IdentityContext {
  canonicalUser?: CanonicalUser;
  redirected: boolean;
  setRedirected: (newSate: boolean) => void;
}

const IS_INTERNAL_CLAIM = 'https://claims.cimpress.io/cimpress_internal';

const IdentityContext = React.createContext<IdentityContext>({} as IdentityContext);

interface Props {
  children: ReactNode;
}

const IdentityProvider = ({ children }: Props): ReactElement => {
  const [canonicalUser, setUserIdentity] = useState<CanonicalUser | undefined>(undefined);
  const [redirected, setRedirected] = useState<boolean>(false);
  const webAuth = useWebAuth();
  const identity = useIdentity();

  useEffect(() => {
    if (webAuth && identity) {
      const userIdentity = {
        isSignedIn: identity.isSignedIn,
        canonicalId: identity.canonicalId,
        isInternal: identity.profile[IS_INTERNAL_CLAIM] || false,
        authToken: identity.accessToken,
        anonymousId: identity.recentAnonymousIds ? identity.recentAnonymousIds[0] : null,
      };
      setUserIdentity(userIdentity);
    } else {
      console.log('An error occurred while initializing Auth.');
      // TODO handle errors and show an error to the customer
    }
  }, [webAuth, identity]);

  return (
    <IdentityContext.Provider value={{ canonicalUser, redirected, setRedirected }}>{children}</IdentityContext.Provider>
  );
};

export { IdentityContext, IdentityProvider };
