import { Environment } from '@vp/digital-environment-lib';
import { getActiveEnvironment } from '../../../loggerHelpers';
import LocalQrCodeUrls from '../../../routing/localUrls.json';
import { defaultLocale } from '../../i18n/locales';

const environment = getActiveEnvironment();
const fadeOutAndGoTo = (locale: string, urlId: string, qrCodeUrls: Array<any> | undefined, params = ''): void => {
  let url: string;
  if (
    ([Environment.Staging].includes(environment) && window.location.href.includes('staging')) ||
    [Environment.Prod].includes(environment)
  ) {
    //Prodcution or Staging

    //eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    url = qrCodeUrls[locale][urlId];
  } else {
    //QA or Local

    //eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    url = `${LocalQrCodeUrls[defaultLocale][urlId]}/${locale.toLowerCase()}`;
  }
  document.body.classList.add('fade-out');
  // setTimeout for safari browsers to work
  setTimeout(function () {
    window.location.assign(url + params);
  }, 250);
};

export { fadeOutAndGoTo };
